import React, { useRef, useState } from "react";
import { Box, Button, FormControl, Grid, Typography } from "@mui/material";

// For Credit cards
// ts-ignore below: The original react-credit-cards package is not compatible
// with React 18 and appears unmaintained. This fork is possibly the best
// updated one at this point, though it doesn't come with Type definitions.
// We are fine with this, as the package is current and maintained.
//
// @ts-ignore
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/es/styles-compiled.css";
import DebitCard from "../DebitCard/DebitCard";
import useApi from "../../api/api";

interface CardFields {
  name: string;
  number: string;
  cvc: string;
  expiry: string;
}

export const CardInput = (
  transferId: string,
  inviteId: string,
  ui_version: string
) => {
  const [loading] = useState(false);
  const [view, setView] = useState("default"); // default, success, error
  const [errorMessage, setErrorMessage] = useState("");
  const api = useApi("");

  const [cardFields, setCardFields] = useState<CardFields>({
    number: "",
    expiry: "",
    name: "",
    cvc: "", // Not used - needed for card display
  });

  const getTargetOrigin = (): string | undefined => {
    const isLocalhost = window.location.host.startsWith("localhost");
    // If we are on localhost, we'll get a DOMException when we try to access
    // the `parent` object since they are on different ports and
    // that makes them technically cross-origin.
    if (isLocalhost) {
      return "localhost";
    }

    // But if this app is loaded inside a sub-domain of ngrok.io
    // then things should work for local development.
    const isNgrokDomain =
      window.location.hostname === "np-dev-app-frontend-cde.ngrok.io";

    const parentHostName = isNgrokDomain
      ? "https://np-dev-app-frontend.ngrok.io"
      : `https://${window.location.hostname.replace("p2c.", "")}`; // Ex: https://p2c.app.staging.neuralpayments.com -> https://app.staging.neuralpayments.com

    return parentHostName;
  };

  const parentMessagePort = useRef<MessagePort>();

  window.addEventListener(
    "message",
    (e: MessageEvent) => {
      const targetOrigin = getTargetOrigin();

      if (!targetOrigin) {
        console.error(
          "could not determine target origin, discarding message event"
        );
        return;
      }
      // We expect to receive messages from a specific parent app.
      // We validate this by checking the origin of the message.
      if (targetOrigin !== "localhost" && e.origin !== targetOrigin) {
        console.error("event origin does not match target origin");
        return;
      }

      // The message port from the parent is sent with an event name `messageChannel`.
      // We'll ignore any other event names since we don't expect any other event
      // to pass us a message port.
      if (e.data?.event !== "messageChannel") {
        return;
      }

      parentMessagePort.current = e.ports[0];
      parentMessagePort.current?.postMessage({
        event: "p2cInputPageLoaded",
        frameHeight: "525px",
      });
    },
    false
  );

  const formIsValid = () => {
    if (
      cardFields.number.length !== 16 ||
      cardFields.name === "" ||
      cardFields.expiry.length !== 4
    ) {
      return false;
    } else {
      return true;
    }
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForm = async () => {
    setIsSubmitted(true);
    if (!formIsValid()) {
      return;
    }

    const transfer_details = {
      invitation_id: inviteId,
      pan: cardFields.number,
      cardholder_name: cardFields.name,
      expiration_data: cardFields.expiry,
    };

    try {
      await api.post(`push_to_card/${transferId}`, transfer_details);
      setView("success");
      parentMessagePort.current?.postMessage({
        event: "p2cExternalInputEntered",
        frameHeight: "200px",
      });
    } catch (error: any) {
      setView("error");
      if (
        error?.response?.data === "This debit card number is not supported."
      ) {
        setErrorMessage(
          "This card is not supported. Please try a different card or Go Back to try a different payment service."
        );
      } else {
        setErrorMessage(
          "A network error has occurred. Please try again later."
        );
      }
      parentMessagePort.current?.postMessage({
        event: "p2cError",
        frameHeight: "420px",
      });
    } finally {
      setIsSubmitted(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardFields({
      ...cardFields,
      [event.target.name]: event.target.value,
    });
  };

  const onChooseDifferentCardClicked = async () => {
    setCardFields({
      number: "",
      expiry: "",
      name: "",
      cvc: "",
    });
    setView("default");
    parentMessagePort.current?.postMessage({
      event: "differentCard",
      frameHeight: "525px",
    });
  };

  interface FiUiCustomization {
    off_network_bg_primary: string;
    off_network_bg_secondary: string;
    off_network_button_color: string;
    off_network_text_color: string;
  }

  const renderP2CScreen = (fiUiCustomization: FiUiCustomization) => {
    switch (view) {
      case "error":
        return (
          <div>
            <Grid
              style={{
                backgroundColor: fiUiCustomization.off_network_bg_primary,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "100vh",
              }}
            >
              <Cards
                cvc={cardFields.cvc}
                expiry={cardFields.expiry}
                name={cardFields.name}
                number={cardFields.number}
              />
              <Box textAlign={"center"}>
                <Typography variant="h5">{errorMessage}</Typography>
              </Box>
              <Box textAlign={"center"}>
                <Button
                  style={ui_version === "new" ? {} : { width: "95%" }}
                  color="secondary"
                  onClick={onChooseDifferentCardClicked}
                >
                  Try a different card
                </Button>
              </Box>
            </Grid>
          </div>
        );

      case "success":
        return (
          <div
            style={{
              backgroundColor: fiUiCustomization.off_network_bg_primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "100vh",
            }}
          >
            <Grid style={ui_version === "new" ? {} : { width: "95%" }}>
              <Box textAlign={"center"}>
                <Typography variant="h4">
                  Your transfer has been initiated.
                </Typography>
              </Box>
            </Grid>
          </div>
        );

      default:
        return (
          <form
            style={{
              backgroundColor: fiUiCustomization.off_network_bg_primary,
            }}
          >
            <Grid
              style={
                ui_version === "new" ? {} : { width: "95%", margin: "auto" }
              }
            >
              {DebitCard(cardFields, handleInputChange, isSubmitted)}
              <Box textAlign={"center"}>
                <FormControl style={{ width: "100%", margin: "8px auto" }}>
                  <Button
                    style={{
                      width: "100%",
                      padding: "16px 0",
                      textTransform: "none",
                      margin: "0",
                    }}
                    onClick={() => submitForm()}
                    disabled={loading}
                  >
                    Confirm
                  </Button>
                </FormControl>
              </Box>
            </Grid>
          </form>
        );
    }
  };

  return { renderP2CScreen };
};

export default CardInput;
