import { Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import P2CPage from "../components/P2C/P2CPage";
import FundingPage from "../components/Funding/FundingPage";

interface P2CPageInterface {
  paymentMethod: {
    app: string;
    i: string;
    t: string;
    token: string;
    ui_version: string;
  };
  fiUiCustomization: {
    off_network_bg_primary: string;
    off_network_bg_secondary: string;
    off_network_button_color: string;
    off_network_text_color: string;
  };
}

const AppRouter = ({ paymentMethod, fiUiCustomization }: P2CPageInterface) => {
  return (
    <div>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            paymentMethod.app === "p2c" ? (
              <P2CPage
                paymentMethod={paymentMethod}
                fiUiCustomization={fiUiCustomization}
              />
            ) : paymentMethod.app === "funding" ? (
              <div style={{ margin: "15px" }}>
                <FundingPage
                  fiUiCustomization={fiUiCustomization}
                  token={paymentMethod.token}
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>Page not found</div>
            )
          }
        />
      </Routes>
    </div>
  );
};

export default AppRouter;
